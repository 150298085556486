import { Component, Input } from '@angular/core';
import { Article } from 'src/app/services/article.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-latest-articles-card',
  templateUrl: './latest-articles-card.component.html'
})
export class LatestArticlesCardComponent {
  @Input() articles: Article[] | null = [];
  @Input() startIndex: number = 0;
  @Input() endIndex: number = 3;
  @Input() onArticleClick: (article: Article) => void = () => {};
  @Input() isLoading: boolean = false;

  environment = environment;
  assetUrl = environment.assetUrl;

  constructor(private router: Router) {}

  getThumbnailUrl(article: Article) {
    return `${this.assetUrl}/thumbnails/${article.thumbnail}`;
  }

  navigateToArticle(article: Article) {
    this.router.navigate([`article/${article.uuid}`], {
      queryParams: {
        sort: 'publication_date',
        direction: 'desc'
      }
    });
  }
}
