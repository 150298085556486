import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '../directives/directives.module';
import { IconsModule } from '../icons.module';
import { PipesModule } from '../pipes/pipes.module';

import { AvatarComponent } from './avatar/avatar.component';
import { KeywordsComponent } from './keywords/keywords.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { QuestionsListComponent } from './questions-list/questions-list.component';
import { ChatInputsComponent } from './chat-inputs/chat-inputs.component';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { FilesListComponent } from './files-list/files-list.component';
import { SearchFilesComponent } from './search-files/search-files.component';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { AlertComponent } from './alert/alert.component';
import { ArticlesSkeletonComponent } from './articles-skeleton/articles-skeleton.component';
import { QuestionsSkeletonComponent } from './questions-skeleton/questions-skeleton.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { ChatsListComponent } from './chats-list/chats-list.component';
import { StarsComponent } from './stars/stars.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ListSkeletonComponent } from './list-skeleton/list-skeleton.component';
import { QuestionsListSkeletonComponent } from './questions-list-skeleton/questions-list-skeleton.component';
import { NavigationArrowsComponent } from './navigation-arrows/navigation-arrows.component';
import { LatestArticlesCardComponent } from './latest-articles-card/latest-articles-card.component';


const MVP_COMPONENTS = [
  AvatarComponent,
  KeywordsComponent,
  MenuComponent,
  HeaderComponent,
  ArticlesListComponent,
  QuestionsListComponent,
  ChatInputsComponent,
  ChatBubbleComponent,
  FilesListComponent,
  SearchFilesComponent,
  ArticlesSkeletonComponent,
  QuestionsSkeletonComponent,
  AlertComponent,
  PasswordInputComponent,
  StarsComponent,
  ChatsListComponent,
  DocumentsListComponent,
  BackButtonComponent,
  ListSkeletonComponent,
  QuestionsListSkeletonComponent,
  NavigationArrowsComponent,
  LatestArticlesCardComponent
];

@NgModule({
  declarations: MVP_COMPONENTS,
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    DirectivesModule,
    PipesModule,
    ReactiveFormsModule,
    IconsModule,
  ],
  exports: MVP_COMPONENTS,
})
export class ComponentsModule { }
